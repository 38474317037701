import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

import Container from "../container";

const PageHeaderContainer = styled.div`
  margin: 80px 0 0;
`;

const TitleBefore = styled(Link)<any>`
  display: inline-block;
  font-size: 20px;
  color: ${props => props.theme.secondaryColor};
  font-family: ${props => props.theme.contentFontFamily};
  font-weight: 400;
  margin: 0 0 10px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Title = styled.h1<any>`
  font-size: 48px;
  color: ${props => props.theme.primaryColor};
  font-family: ${props => props.theme.headerFontFamily};
  font-weight: 400;
  margin: 0 0 13px;
`;

const Author = styled.p<any>`
  font-family: ${props => props.theme.contentFontFamily};
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 60px;
`;

export interface IBlogHeaderProps {
  title: string;
  titleBefore: string;
  author: string
}

export default function BlogHeader(props: IBlogHeaderProps): JSX.Element {
  const {
    title,
    titleBefore,
    author,
  } = props;

  return (
    <PageHeaderContainer>
      <Container> 
        <TitleBefore to="/blog">{titleBefore}</TitleBefore>
        <Title>{title}</Title>
        <Author>{author}</Author>
      </Container>
    </PageHeaderContainer>
  );
}