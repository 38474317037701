import React from "react";
import { graphql } from 'gatsby';
import propTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "gatsby";

import Layout from "../components/layout/layout";
import BlogHeader from "../components/blog/BlogHeader";
import Container from "../components/container";
import { IBlogDetail } from "../utils/types";

interface IBlogPost {
  data: {
    feedMediumBlog: IBlogDetail,
    quoteFile: {
      publicURL: string,
    }
  }
}

const BlogContent = styled.div<any>`
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 24px;
  }

  p {
    font-family: ${props => props.theme.contentFontFamily};
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 31px;
  }

  ul {
    list-style-type: circle;
  }

  li {
    font-family: ${prosp => prosp.theme.contentFontFamily};
    font-size: 18px;
    line-height: 36px;
    margin-bottom: 0;
  }

  a {
    color: ${props => props.theme.secondaryColor};
  }

  blockquote {
    max-width: 800px;
    margin: 0 auto;
    position:relative;
    background-color:white;
    padding: 10px;
    margin-bottom: 31px;
    color: #1E3961;
  }

  blockquote p,
  blockquote em {
    position:relative;
    display: block;
    border: 1px solid #1E3961;
    padding: 60px;
    margin: 0;
    font-family: Lato;
    font-size: 18px;
    line-height: 1.4;
    color: #1E3961;
  }

  blockquote p a {
    font-size: 18px;
    margin-top: 20px;
    display: block;
    font-style: italic;
    color: #1E3961;
  }

  blockquote:before {
    content: '';
    position: absolute;
    font-family: ${props => props.theme.headerFontFamily};
    width: 24px;
    height: 24px;
    left: 33px;
    top: 47px;
    background-image: url(${props => props.quoteURL});
    background-size: 24px 24px;
  }

  figure {
    margin: 41px auto 31px;
    text-align: center;

    img {
      margin-bottom: 16px;
    }
  }

  figcaption {
    font-size: 14px;
    line-height: 20px;
    font-style: italic;
  }
`;

const BlogFooter = styled.div<any>`
  border-top: 1px solid ${props => props.theme.borderColor};
  padding-top: 60px;
  margin: 80px auto 120px;

  a {
    font-family: ${props => props.theme.contentFontFamily};
    color: ${props => props.theme.secondaryColor};
    text-decoration: none;
  }
`;

export default function BlogDetail(props : IBlogPost) : JSX.Element {
  
  const { 
    title,
    creator,
    content
  } = props.data.feedMediumBlog;

  const quoteURL = props.data.quoteFile.publicURL;

  // content.encoded += `<blockquote class="wj wk wl"><p id="f3f6" class="gs gt pv gv b gw gx gy gz ha hb hc hd he hf hg hh hi hj hk hl hm hn ho hp hq gn cg" data-selectable-paragraph="">A customer insight is “a non-obvious understanding about your customers, which if acted upon, has the potential to change their behavior for mutual benefit.” — <a href="https://customerinsightleader.com/" class="cm hs" rel="noopener nofollow">Paul Laughlin</a></p></blockquote>`

  return (
    <Layout title={title}>
      <BlogHeader
        title={title}
        titleBefore="Strategica Blog"
        author={creator}
      />
      <Container>
        <BlogContent dangerouslySetInnerHTML={{ __html: content.encoded }} quoteURL={quoteURL} />
        <BlogFooter>
          <Link to="/blog">&lt; Return to the Strategica Blog</Link>
        </BlogFooter>
      </Container>
    </Layout>
  );
}

export const query = graphql`
query($slug: String!) {
  feedMediumBlog(slug: {eq: $slug}) {
    title
    creator
    pubDate
    content {
      encoded
    }
  }
  quoteFile: file(relativePath: {eq: "quote-mark-blue.svg"}) {
    publicURL
  }
}
`;